import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuModuleComponent } from './menu-module.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

@NgModule({
	declarations: [MenuModuleComponent],
	exports: [MenuModuleComponent],
	imports: [CommonModule, IonicModule, RouterModule],
})
export class MenuModuleModule {}
