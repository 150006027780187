import { Component, OnInit } from '@angular/core';
import { ApiService } from 'lib/services/api/api.service';
import { LeadWatcherService } from 'src/app/services/lead-watcher/lead-watcher.service';
import { MenuService } from 'src/app/services/menu/menu.service';
import { PhotoService } from 'lib-user/services/photo/photo.service';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';
import { UtilService } from 'lib-user/services/util/util.service';

@Component({
	selector: 'app-menu-module',
	templateUrl: './menu-module.component.html',
	styleUrls: ['./menu-module.component.scss'],
})
export class MenuModuleComponent implements OnInit {
	profileSpinner: boolean = false;
	constructor(
		public userProvider: UserProviderService,
		private util: UtilService,
		private photoService: PhotoService,
		private api: ApiService,
		public menuService: MenuService,
		public readonly leadWatcher: LeadWatcherService
	) {}

	ngOnInit() {}

	goTo(notif?) {
		if (notif) this.leadWatcher.workRequests.changed = false;
		this.menuService.toggleMenu(true, false);
	}

	async logoutPrompt() {
		const alert = await this.util.createAlert(
			'Logout?',
			true,
			'Logout from NexGen Vendor panel?',
			{
				text: 'Cancel',
				handler: async () => {},
			},
			{
				text: 'Yes',
				handler: async () => {
					await this.logout();
				},
			}
		);
		alert.present();
	}

	async gotoProfile() {
		this.util.navigateRoot(['/profile']);
		this.menuService.toggleMenu(true, false);
	}

	openHelp() {
		const url = 'https://nexgentaxes.com/docs/vendor-help-center/';
		window.open(
			url,
			'_blank',
			'toolbar=no, location=no, directories=no,status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=yes, width=1200, height=800'
		);
	}

	async logout() {
		await this.userProvider.logout();
	}
}
