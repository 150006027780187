/* eslint @typescript-eslint/naming-convention:off */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhotoService } from 'lib-user/services/photo/photo.service';
import { from, Observable } from 'rxjs';
import { retry, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private http: HttpClient, private photoService: PhotoService) {}

	uploadImage(file) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData();
				formData.append('fi', file);
				const data = await this.photoService.uploadPhotoToS3(formData);
				resolve(data.Location);
			} catch (e) {
				reject(e);
			}
		});
	}

	/* eslint id-blacklist:off*/
	sendOtp(phoneNumber) {
		return new Promise<any>((resolved, rejected) => {
			if (!phoneNumber) {
				rejected('No phone number, please refresh browser');
			}
			const url = environment.apiProdBase + 'sendOTP';
			const data = {
				number: phoneNumber.replace(/-/g, ''),
			};
			this.http.post<any>(url, data).subscribe(resolved, rejected);
		});
	}

	// Google 2FA
	newUserG2FA() {
		return new Promise<string>((resolve, reject) => {
			const httpHeaders = new HttpHeaders({
				'x-rapidapi-key':
					'4454e0f926mshef5aae0fb777da9p128cf5jsn4402fc8b7ca8',
			});

			const url = 'https://google-authenticator.p.rapidapi.com/new/';
			this.http
				.get(url, {
					headers: httpHeaders,
					responseType: 'text',
				})
				.subscribe(
					(res) => {
						if (res.includes('Your new secret: ')) {
							const split = res
								.split('Your new secret: ')[1]
								.trim();
							resolve(split);
						} else {
							reject('No secret sent');
						}
					},
					(err) => reject(err)
				);
		});
	}

	enrollUserG2FA(secret, email) {
		return new Promise<string>((resolve, reject) => {
			const httpHeaders = new HttpHeaders({
				'x-rapidapi-key':
					'4454e0f926mshef5aae0fb777da9p128cf5jsn4402fc8b7ca8',
			});

			let params = new HttpParams();
			params = params.append('secret', secret);
			params = params.append('account', email);
			params = params.append('issuer', 'NexGen Vendor');

			const url = 'https://google-authenticator.p.rapidapi.com/enroll/';
			this.http
				.get(url, {
					headers: httpHeaders,
					responseType: 'text',
					params,
				})
				.subscribe(resolve, reject);
		});
	}

	validateUserG2FA(secret, otpCode) {
		return new Promise<string>((resolve, reject) => {
			const httpHeaders = new HttpHeaders({
				'x-rapidapi-key':
					'4454e0f926mshef5aae0fb777da9p128cf5jsn4402fc8b7ca8',
			});

			let params = new HttpParams();
			params = params.append('secret', secret);
			params = params.append('code', otpCode);

			const url = 'https://google-authenticator.p.rapidapi.com/validate/';
			this.http
				.get(url, {
					headers: httpHeaders,
					responseType: 'text',
					params,
				})
				.subscribe(
					(res) => {
						resolve(res);
					},
					(err) => reject(err)
				);
		});
	}

	/**
	 * create customer
	 * @param data
	 * @param url
	 * @returns
	 */
	createCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams()
			.set('description', data.description)
			.set('email', data.email)
			.set('phone', data.phone)
			.set('name', data.name);
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 * check customer
	 * @param url
	 * @returns
	 */
	checkCustomer(url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/json',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};

		return this.http
			.post(url, {}, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	attachPmToCustomer(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams().set('customer', data.customer);
		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	/**-----------------------------------------------------
	 *
	 * @param data
	 * @param url
	 * @returns
	 */
	stripePay(data: any, url: string): Observable<any> {
		const httpOptions = {
			headers: new HttpHeaders({
				async: 'true',
				crossDomain: 'true',
				'content-type': 'application/x-www-form-urlencoded',
				authorization: 'Bearer ' + environment.stripe.secret,
				'cache-control': 'no-cache',
				'Access-Control-Allow-Origin': '*',
			}),
		};
		const params = new HttpParams()
			.set('amount', data.amount) //data.amount)
			.set('currency', data.currency)
			.set('customer', data.customer)
			.set('payment_method', data.payment_method)
			.set('confirm', data.confirm)
			.set('error_on_requires_action', data.error_on_requires_action);

		return this.http
			.post(url, params, httpOptions)
			.pipe(timeout(6000), retry(1));
	}

	postHttpData(url: string, body: any): Promise<any[]> {
		return new Promise<any>((resolve, reject) => {
			const httpOptions = {
				headers: new HttpHeaders({
					api_key: environment.apiBaseKey,
				}),
			};

			try {
				this.http
					.post<any[]>(url, body, httpOptions)
					.pipe()
					.subscribe({
						next: () => {
							console.log('next.....');
							resolve(true);
						},
						error: (e: any) => {
							console.log('error.....', e);
							reject(e);
						},
						complete: () => console.log('completed.....'),
					});
			} catch (e) {
				reject(e);
			}
		});
	}
}
