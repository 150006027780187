import { Component, Input, OnInit } from '@angular/core';
import { IReview } from 'lib/models/reviews.model';
import { ApiService } from 'lib/services/api/api.service';
import { FirebaseApiService } from 'lib/services/firebase/firebase.api.service';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';
import { UtilService } from 'lib-user/services/util/util.service';

@Component({
	selector: 'app-steps-status',
	templateUrl: './steps-status.component.html',
	styleUrls: ['./steps-status.component.scss'],
})
export class StepsStatusComponent implements OnInit {
	publicReviews: IReview[] = [];
	@Input() shape: string = 'line';

	_color = '#ff0000';
	constructor(
		private util: UtilService,
		public readonly userProvider: UserProviderService,
		private firebaseApi: FirebaseApiService
	) {}

	async ngOnInit() {}

	get percentage() {
		const perc = this.userProvider.getOnboardingProgress();
		this._color =
			perc < 50
				? '#fb0a0a'
				: perc >= 50 && perc < 75
				? '#ff8503'
				: perc >= 75 && perc < 90
				? '#bde913'
				: '#15d11e';

		return perc;
	}

	async showPercentAlert() {
		const alert = await this.util.createAlert(
			'Onboarding Complete',
			true,
			'Finish all the steps below to finish onboarding your vendor account.',

			{
				text: 'Okay',
				handler: () => {},
			}
		);
		await alert.present();
	}

	goToPersonalInfo() {
		this.util.navigateForward('onboarding/personal-info');
	}

	goToWizard() {
		this.util.navigateForward('onboarding/wizard');
	}

	goToPublicProfile() {
		this.util.navigateForward('profile/public-facing');
	}

	goToAuth() {
		this.util.navigateForward('onboarding/choose-auth-method');
	}

	goToUpload() {
		this.util.navigateForward('onboarding/upload');
	}

	gotoTargeting() {
		this.util.navigateForward('onboarding/pro-targeting');
	}
}
