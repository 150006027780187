import { ActivatedRoute, CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable({
	providedIn: 'root',
})
export class NavigateAwayGuard
	implements CanDeactivate<ComponentCanDeactivate>
{
	constructor(private router: Router) {}

	canDeactivate(component: ComponentCanDeactivate): boolean {
		return true;
	}
}
