import { Injectable } from '@angular/core';
import { ITemplate, User } from './user.model';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from 'lib/services/auth/auth.service';
import { FirebaseApiService } from 'lib/services/firebase/firebase.api.service';
import { environment } from 'src/environments/environment';
import { StorageService } from 'src/app/services/storage/storage.service';
import { LeadWatcherService } from 'src/app/services/lead-watcher/lead-watcher.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
	Client,
	ConnectionState,
	Conversation,
	Message,
	Participant,
} from '@twilio/conversations';
import { debounceTime } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ConversationApiService } from 'lib/services/conversations/conversations-api.service';
import { UtilService } from '../util/util.service';
import { ModalController } from '@ionic/angular';
import { Formatting } from 'flatpickr/dist/types/instance';
import { FormattingService } from 'lib/utils/formatting.service';

@Injectable({
	providedIn: 'root',
})
export class UserProviderService {
	isLoginMode = true;
	loggedInUser: User = {} as User;
	myTemplates: ITemplate[] = [];
	tasksListener;
	userUpdated$ = new Subject();

	//-------------------- chat vars

	conversationClient: Client;
	conversation: Conversation;
	chatList: Array<{
		chat: Conversation;
		unreadCount: number;
		lastMessage: string;
		sid?: string;
		time: string;
		wrId: string;
		requestType: string;

		vendorBusinessName: string;
		vendorFirstName: string;
		vendorLastName: string;
		vendorPhoto: string;

		userFirstName: string;
		userLastName: string;
		userPhoto: string;
		hide?: boolean;
		wrTitle?: string;
		createdAt?: any;
		_isCanceled?: boolean;
	}> = [];

	messages: Array<Message> = [];
	messageSending: boolean = false;
	message: string;
	error: string;
	currentConversation: Conversation;
	currentTime: Date;
	isTyping = false;
	_alreadyListening = false;
	_convLoader = false;
	_loadChat = false;
	_messagedAdded$ = new Subject();
	_showChatRoom = false;
	_showChatList = false;

	notifySubject = new Subject<any>();
	notifyObservable$ = this.notifySubject.asObservable();
	//------------------------------

	constructor(
		private firebaseApi: FirebaseApiService,
		private storage: StorageService,
		private leadWatcher: LeadWatcherService,
		private afAuth: AngularFireAuth,
		private auth: AuthService,
		private store: AngularFirestore,
		private http: HttpClient,
		private datePipe: DatePipe,
		private conversationApiService: ConversationApiService,
		private util: UtilService,
		public modalCtrl: ModalController,
		private format: FormattingService
	) {
		this.listenAccountChanges();
		this.listenQuestionnaireChanges();
	}

	notify(message: any) {
		this.notifySubject.next(message);
	}

	async getVendorReviews() {
		return new Promise<any>(async (resolve, reject) => {
			try {
				const r: any = await this.firebaseApi.findAll(
					'reviews',
					(ref: any) =>
						ref.where('vendorId', '==', this.loggedInUser.id)
				);
				this.loggedInUser.publicReviews = r && r.length ? r : [];
				console.log(
					'>>>> reviews -=>',
					this.loggedInUser.publicReviews
				);
				resolve(true);
			} catch (e) {
				resolve(false);
			}
		});
	}

	async listenAccountChanges() {
		try {
			const workCollection = this.store.collection<any>('users');
			this.tasksListener = workCollection
				.snapshotChanges(['modified'])
				.subscribe(async () => {
					if (this.loggedInUser?.id) {
						const a = await this.firebaseApi.findOneById(
							'users',
							this.loggedInUser.id
						);
						if (a && a.credits >= 0) {
							this.loggedInUser.credits = a.credits;
							this.loggedInUser.emailVerified = a.emailVerified;
							this.loggedInUser.approvedToWork = a.approvedToWork;
							this.leadWatcher._showDash = false;
						}
						this.userUpdated$.next('');
					}
				});
		} catch (e) {
			throw new Error('Could not listen to task changes');
		}
	}

	async listenQuestionnaireChanges() {
		try {
			const workCollection = this.store.collection<any>('questionnaire');
			this.tasksListener = workCollection
				.snapshotChanges(['modified'])
				.subscribe(async () => {
					this.userUpdated$.next('');
				});
		} catch (e) {
			throw new Error('Could not listen to task changes');
		}
	}

	checkAuthState() {
		return new Promise(async (resolve, reject) => {
			const unsubscribe = await this.afAuth.onAuthStateChanged((user) => {
				unsubscribe();
				this.load(user?.uid).then(() => resolve(user));
			}, reject);
		});
	}

	async setLoggedInUser(user: User) {
		console.log('User: ', user);
		if (user.teamId) {
			const team = await this.firebaseApi.findOneById(
				'teams',
				user.teamId
			);
			user.myTeam = team;
		}
		if (!user.recipientEmail) user.recipientEmail = user.email;

		Object.assign(this.loggedInUser, user);

		const visitorId = await this.getFingerprint();

		if (visitorId !== this.loggedInUser.trustBrowserVisitorId) {
			await this.firebaseApi.updateOne('users', this.loggedInUser.id, {
				verifiedAuth: false,
			});
			this.loggedInUser.verifiedAuth = false;
		}
	}

	getFingerprint() {
		return new Promise<string>((resolve, reject) => {
			FingerprintJS.load()
				.then((fp) => fp.get())
				.then((result) => {
					const visitorId = result.visitorId;
					resolve(visitorId);
				});
		});
	}

	load(userId) {
		return new Promise(async (resolve, reject) => {
			const isLoginExpired = (user: User) => {
				const expiredDate = user.lastSeen.seconds / 60 + 15;
				const nowDate = new Date().getTime() / 60000;
				if (nowDate >= expiredDate) return true;
				else return false;
			};
			try {
				if (userId) {
					const user: User = await this.auth.getSingleUser(userId);

					if (user && user.role !== 'vendor')
						throw new Error('not a vendor');

					await this.setLoggedInUser(user);
					let loginExpired = false;

					if (user.lastSeen) loginExpired = isLoginExpired(user);
					if (loginExpired) {
						this.logout();
						return;
					}

					await this.lastSeen(new Date());

					if (this.loggedInUser.id) {
						this.getVendorReviews();

						const teamArray: string[] = this.loggedInUser.myTeam
							? this.loggedInUser.myTeam.members
							: [];
						if (this.loggedInUser.myTeam)
							teamArray.push(this.loggedInUser.myTeam.ownerId);
						else teamArray.push(this.loggedInUser.id);

						this.loggedInUser.teamArray = teamArray;
						await this.leadWatcher.getAppointments(
							true,
							this.loggedInUser.id
						);
						this.leadWatcher.listenTaskChanges(
							this.loggedInUser.id,
							teamArray
						);
						this.leadWatcher.listenWorkRequestChanges(
							this.loggedInUser.id,
							teamArray
						);
						this.leadWatcher.listenAppointmentsChange(
							this.loggedInUser.id
						);
						this.listenConversations(this.loggedInUser.id);

						await this.storage.init();
						resolve(true);
					}
				} else {
					resolve(false);
					await this.storage.init();
				}
			} catch (e: any) {
				await this.storage.init();
				console.log('e-=>', e.message);
				if (e.message === 'not a vendor') {
					resolve('not a vendor');
				}
			}
		});
	}

	async logout(): Promise<any> {
		await this.firebaseApi.updateOne('users', this.loggedInUser.id, {
			verifiedAuth: false,
		});
		this.unsubscribeListeners();
		await this.auth.logout();
		this.loggedInUser = {} as User;
		location.reload();
	}

	unsubscribeListeners() {
		if (this.leadWatcher.tasksListener)
			this.leadWatcher.tasksListener.unsubscribe();
		if (this.leadWatcher.workRequestsListener)
			this.leadWatcher.workRequestsListener.unsubscribe();
		if (this.leadWatcher.apppointmentsListener)
			this.leadWatcher.apppointmentsListener.unsubscribe();
	}

	async lastSeen(date, userId?) {
		await this.firebaseApi.updateOne(
			'users',
			userId ? userId : this.loggedInUser.id,
			{
				lastSeen: date,
			}
		);
	}

	/**------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	 *-------------------------------------------------------------------
	/**------------------------------------------------------------------
	 *
	 * @param vendorId
	 */

	get totalUnreadMsg() {
		let totalUnreadMsg = 0;
		this.chatList.forEach((el) => {
			totalUnreadMsg += el.unreadCount;
		});
		return totalUnreadMsg;
	}

	async listenConversations(vendorId) {
		try {
			const convCollection = this.store.collection<any>(
				'conversations',
				(ref) => ref.where('vendorId', '==', vendorId)
			);

			this.tasksListener = convCollection
				.valueChanges(['added'])
				.pipe(debounceTime(800))
				.subscribe((action) => {
					if (action && action.length > 0) {
						console.log(
							'Action +++++++++++++++++++++++-> ',
							action
						);
						setTimeout(() => this.connectTwilio(vendorId), 1000);
					}
				});
		} catch (e) {
			console.log('Abort Listener', e);
		}
	}

	/**----------------------------------------------connect Twilio
	 *
	 */
	async connectTwilio(vendorId: string) {
		if (!this.conversationClient && !this._alreadyListening) {
			this._convLoader = true;
			try {
				const token = await this.conversationApiService.getToken(
					vendorId,
					this.loggedInUser.email
				);
				this.conversationClient = new Client(token);
				this._convLoader = false;
				this._alreadyListening = true;
				this.listenToEvents(vendorId);
			} catch (e) {
				this._convLoader = false;
				console.log(' get token error', e);
			}
		} else {
			console.log('### conv client already connected--------------->>');
			await this.fetchUserChats(vendorId);
		}
	}

	/**--------------------------------------------
	 *
	 */
	async listenToEvents(vendorId) {
		this.conversationClient.on('initialized', async () => {
			console.log('Client initialized--------------->> listening');
			await this.fetchUserChats(vendorId);
		});

		this.conversationClient.on('initFailed', (error: any) => {
			console.log('Client initialization failed: ', error);
		});

		this.conversationClient.on(
			'connectionStateChanged',
			(state: ConnectionState) => {
				console.log('Connection state change: ', state);
			}
		);

		this.conversationClient.on('connectionError', (error: any) => {
			console.log('Connection error: ', error);
		});

		this.conversationClient.on('tokenAboutToExpire', async () => {
			console.log('About to expire');
			const token = await this.conversationApiService.getToken(
				vendorId,
				this.loggedInUser.email
			);

			this.conversationClient = await this.conversationClient.updateToken(
				token
			);
		});

		this.conversationClient.on('tokenExpired', () => {
			console.log('Token expired');
			this.conversationClient.removeAllListeners();
		});

		this.conversationClient.on(
			'conversationAdded',
			(conv: Conversation) => {
				this.currentTime = new Date();
				setTimeout(async () => {
					if (
						conv.dateCreated &&
						conv.dateCreated > this.currentTime
					) {
						console.log('Conversation added', conv);
						await conv.setAllMessagesUnread();
						//addMessageToChatList(conv)
					}
				}, 500);
			}
		);

		this.conversationClient.on('messageAdded', async (msg: Message) => {
			console.log('Message added', msg);
			if (
				this.currentConversation &&
				this.currentConversation.sid === msg.conversation.sid
			) {
				this.messages.push(msg);
				await this.currentConversation.updateLastReadMessageIndex(
					msg.index
				);
				this.chatList = this.chatList.map((el: any) => {
					if (el.chat.sid === this.currentConversation.sid) {
						el.lastMessage = msg.body;

						let time = '...';
						const dt = el.chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;

							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}
						el.time = time;
					}
					return el;
				});
				this.sortChatList();
				this._messagedAdded$.next('');
			} else {
				this.chatList = this.chatList.map((el: any) => {
					if (el.chat.sid === msg.conversation.sid) {
						el.lastMessage = msg.body;
						el.unreadCount++;
						let time = '...';
						const dt = el.chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;
							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}
						el.time = time;
					}
					return el;
				});
			}

			this.sortChatList();

			// this.scrollToBottom();
		});

		this.conversationClient.on('typingStarted', (user: Participant) => {
			// console.log('typing..', user);
			if (user?.conversation?.sid === this.currentConversation?.sid)
				this.isTyping = true;
		});

		this.conversationClient.on('typingEnded', (user: Participant) => {
			console.log('typing end..', user);
			if (user?.conversation?.sid === this.currentConversation?.sid)
				this.isTyping = false;
		});
	}

	/**-------------------------------------------------
	 *
	 * @param vendorId
	 */
	async fetchUserChats(vendorId: string) {
		return new Promise<any>(async (resolve, reject) => {
			if (this.conversationClient) {
				console.log('#### 1- chat fetch started');
				const f: any[] = await this.fetchUserChatsTemp(vendorId);
				if (f && f.length > 0) {
					this.chatList = f;
					this.sortChatList();
				}
				console.log('#### 4- chat fetch done');
				resolve(true);
			} else {
				console.log('#### chat fetch skipped');
				resolve(true);
			}
		});
	}

	/**-------------------------------------------------------
	 *
	 * @param vendorId
	 * @returns
	 */
	async fetchUserChatsTemp(vendorId: string) {
		return new Promise<any>(async (resolve, reject) => {
			try {
				let chatListTemp = [];
				this._loadChat = true;
				console.log('#### 2- start temp fetch');

				const c = await this.firebaseApi.findConversationsByParticipant(
					vendorId
				);

				// filter conversations
				const conversations = c.filter((el: any) => !el?.ticketId);

				if (conversations && conversations.length > 0) {
					let index = 0;
					for (let myConv of conversations) {
						const chat =
							await this.conversationClient.getConversationBySid(
								myConv.conversationSid
							);

						const unreadCount = await chat.getUnreadMessagesCount();
						const lastMessage: any = await chat.getMessages();
						let body: any;

						if (lastMessage && lastMessage.items.length > 0) {
							const idx = chat.lastReadMessageIndex || 0;

							body =
								lastMessage.items[idx] &&
								lastMessage.items[idx].body
									? lastMessage.items[idx].body
									: 'Hi! How may I help you?';
						}

						let time = '';
						const dt = chat?.lastMessage?.dateCreated;
						if (dt) {
							const minute = 1000 * 60;
							const currentDate = new Date();
							const currentMins = Math.round(
								currentDate.getTime() / minute
							);
							const msgDate = new Date(dt);
							const msgMins = Math.round(
								msgDate.getTime() / minute
							);
							const diffMins = currentMins - msgMins;

							if (diffMins < 5) time = 'Just now';
							else if (diffMins > 5 && diffMins < 30) {
								time = 'Since ' + diffMins + ' mins';
							} else {
								time = this.datePipe.transform(
									msgDate,
									'MM/dd/yy, hh:mm a'
								);
							}
						}

						// find the the work request status & if already hired
						let _isCanceled = false;

						const _wrId = myConv.workRequestId;
						let wrTitle;

						if (_wrId) {
							const _c = await this.firebaseApi.findOneById(
								'workRequests',
								_wrId
							);

							if (_c.progress === 'canceled') _isCanceled = true;
							wrTitle = this.format.getFullPackage(_c);
						}

						let obj: any = {
							chat,
							unreadCount: unreadCount ? unreadCount : 0,
							sid: chat.sid,
							lastMessage: body ? body : '',
							time,

							requestType: myConv.requestType,
							wrId: myConv.workRequestId,
							wrTitle: wrTitle ? wrTitle : '',
							vendorBusinessName: myConv.vendorBusinessName,
							vendorFirstName: myConv.vendorFirstName,
							vendorLastName: myConv.vendorLastName,
							vendorPhoto: myConv.vendorPhoto,
							vendorId: myConv.vendorId ? myConv.vendorId : '',

							userFirstName: myConv.userFirstName,
							userLastName: myConv.userLastName,
							userPhoto: myConv.userPhoto,
							createdAt: myConv.createdAt ? myConv.createdAt : '',
							_isCanceled,
						};

						chatListTemp.push(obj);
						// console.log(index, 'obj --->', obj);
						++index;
					}

					// console.log('### 3- end chatList ---->', chatListTemp);
					this._loadChat = false;
					resolve(chatListTemp);
				} else {
					this._loadChat = false;
					resolve([]);
				}
			} catch (e) {
				console.log('### 3- end chatList err ->', e);
				this._loadChat = false;
				resolve([]);
			}
		});
	}

	getOnboardingProgress(): any {
		let percentage = 0;
		const totalChecking = 8;

		// 1 check zipcode
		if (this.loggedInUser?.business?.address?.zipcode) percentage += 1;

		// 2 check 2FA
		if (this.loggedInUser.twoFA) percentage += 1;

		// 3 public Files
		if (
			this.loggedInUser.publicFiles &&
			this.loggedInUser.publicFiles.length >= 2
		)
			percentage += 1;

		// 4 public Faqs
		if (
			this.loggedInUser.publicFaqs &&
			this.loggedInUser.publicFaqs.length >= 6
		)
			percentage += 1;

		// 5 public Reviews
		if (
			this.loggedInUser.publicReviews &&
			this.loggedInUser.publicReviews.length >= 3
		)
			percentage += 1;

		// 6 questionnaire Id
		if (this.loggedInUser?.questionnaireId) percentage += 1;

		// 7 pro Targeting
		if (this.loggedInUser?.proTargeting?.completed) percentage += 1;

		// 8 business name
		if (this.loggedInUser?.business?.name) percentage += 1;

		// //-------------------------------------

		// // 9 pro photo
		// if (this.loggedInUser.profilePhoto) percentage += 1;

		return (percentage * 100) / totalChecking;
	}

	checkVendorStatus(): boolean {
		let c = false;
		if (
			this.loggedInUser?.address?.zipcode && //------------1
			this.loggedInUser?.twoFA && //-----------------------2
			this.loggedInUser?.publicFiles && //-----------------3
			this.loggedInUser?.publicReviews.length >= 3 &&
			this.loggedInUser?.publicFaqs && //------------------4
			this.loggedInUser?.publicFaqs.length >= 6 &&
			this.loggedInUser?.questionnaireId && //-------------5
			this.loggedInUser?.proTargeting && //----------------6
			this.loggedInUser?.proTargeting?.completed &&
			this.loggedInUser?.business?.name //-----------------7
		)
			c = true;

		return c;
	}

	resetChat() {
		this.currentConversation = null;
		this._showChatRoom = false;
		this._showChatList = false;
	}

	/**-------------------------------------------------------
	 *
	 */

	sortChatList() {
		if (this.chatList && this.chatList.length > 0) {
			// Sort by `name` property in ascending order
			this.chatList.sort((a, b) =>
				a.userFirstName.localeCompare(b.userFirstName)
			);
			let user = '';
			this.chatList.forEach((el) => {
				if (user !== el.userFirstName) {
					user = el.userFirstName;
				} else {
					el.hide = true;
				}
			});
			console.log('Chat list >>>>>>>>>>>++++++++>', this.chatList);
		}
	}

	/**--------------------
	 *
	 * @param i
	 */
	checkMsgDate(i: number): string {
		let chatDate = '';
		let prevDate = '';
		const curDate = this.datePipe.transform(
			this.messages[i].dateUpdated,
			'MM.dd.yy'
		);

		if (i > 0)
			prevDate = this.datePipe.transform(
				this.messages[i - 1].dateUpdated,
				'MM.dd.yy'
			);

		chatDate = prevDate === curDate ? '' : curDate;
		const today = this.datePipe.transform(new Date(), 'MM.dd.yy');
		if (chatDate && chatDate === today) chatDate = 'Today';
		return chatDate;
	}

	closeModal() {
		this.modalCtrl.dismiss(); // Closes the modal
	}

	closeTargeteModal(prevScreen: string) {
		if (prevScreen === 'dash') this.goTo('profile');
		else this.modalCtrl.dismiss(); // Closes the modal
	}

	getVendorChargeAmount(
		packageType: Array<string>,
		requestType: string,
		globalTotal: number
	) {
		let vendorChargeAmount: number = 0;
		switch (requestType) {
			case 'Tax Return':
				if (
					packageType?.length === 1 &&
					packageType?.includes('business')
				)
					vendorChargeAmount = 50;
				else if (
					packageType?.length > 1 &&
					packageType?.includes('business')
				)
					vendorChargeAmount = 70;
				else vendorChargeAmount = 30;
				break;
			default:
				vendorChargeAmount = 30;
		}

		const percentOfTotal = globalTotal * 0.15;

		return vendorChargeAmount + percentOfTotal;
	}

	goTo(url: string) {
		this.leadWatcher._edit = true;
		if (url === 'profile') {
			this.util.navigateRoot(url);
			this.leadWatcher._edit = false;
		}
	}

	getQuestions(q) {
		let quests1 = q?.questionnaire.filter(
			(q) =>
				!q.skip &&
				q.group === '' &&
				q.question.toLowerCase() !=
					'What is the name of your business?'.toLowerCase() &&
				q.question.toLowerCase() !=
					'What is your EIN number?'.toLowerCase() &&
				q.answers.find((a) => a.value !== false)
		);

		if (q.bizList.length > 0) {
			q.bizList.forEach((el) => {
				quests1 = quests1.concat(el.data);
			});
		}

		let quest2 = q?.questionnaire.filter(
			(q) =>
				!q.skip &&
				(q.group === 'issues' ||
					q.group === 'notice' ||
					q.group === 'details' ||
					q.group === 'accounting' ||
					q.group === 'payroll' ||
					q.group === 'entity' ||
					q.group === 'itin' ||
					q.group === 'dep') &&
				q.answers.find(
					(a: any) => (a.value && a.value !== false) || a.value === 0
				)
		);
		return [...quests1, ...quest2];
	}
}
