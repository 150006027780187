/* eslint @typescript-eslint/naming-convention:off */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ConversationApiService {
	constructor(private http: HttpClient) {}

	createConversation(data) {
		const url = environment.conversationApi + 'api/chat/createConversation';
		return this.http.post<any>(url, data).toPromise();
	}

	sendTicketToSupportEmail(data: {
		ConversationSid: string;
		Author: string;
		Body: string;
	}) {
		const url =
			environment.conversationApi + 'api/chat/sendTicketToSupportEmail';
		return this.http.post<any>(url, data).toPromise();
	}

	async getToken(userId, userEmail) {
		const url = environment.conversationApi + 'api/chat/token';

		const { token } = await this.http
			.post<any>(url, {
				userId,
				userEmail,
			})
			.toPromise();
		return token;
	}
}
