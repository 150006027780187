import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

console.log('prod env:', environment.production);
if (environment.production) {
	// window.console.log = function () { }; // disable any console.log debugging statements in production mode
	console.log = function () {};
	console.error = function () {};
	console.warn = function () {};
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));

defineCustomElements(window);
