import { HttpClientModule } from '@angular/common/http';
import {
	APP_INITIALIZER,
	CUSTOM_ELEMENTS_SCHEMA,
	ErrorHandler,
	NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MenuModuleModule } from './components/menu-module/menu-module.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthenticationService } from 'lib/services/firebase/firebase-authentication.service';
import { FirestoreService } from 'lib/services/firebase/firestore.service';
import { DatePipe } from '@angular/common';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';
import { GooglePlus } from '@ionic-native/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { register } from 'swiper/element/bundle';
import { DocumentUploadComponent } from './components/document-upload/document-upload.component';

register();

export const initUserProviderFactory =
	(provider: UserProviderService) => async () => {
		await provider.checkAuthState();
	};

@NgModule({
	declarations: [AppComponent, DocumentUploadComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		BrowserModule,
		AppRoutingModule,
		IonicModule.forRoot(),
		MenuModuleModule,
		HttpClientModule,
		IonicStorageModule.forRoot(),
		BrowserAnimationsModule,
		NgIdleKeepaliveModule.forRoot(), // use NgIdleModule.forRoot() if not using keepalive
		AngularFireModule.initializeApp(environment.firebaseConfig),
		AngularFirestoreModule,
		AngularFireAuthModule,
	],
	providers: [
		AuthenticationService,
		DatePipe,
		FirestoreService,
		GooglePlus,
		SignInWithApple,
		{
			provide: APP_INITIALIZER,
			useFactory: initUserProviderFactory,
			deps: [UserProviderService],
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
