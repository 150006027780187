// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*eslint max-len:off */

export const environment = {
	production: false,
	userRole: 'vendor',
	apiBase: 'https://staging.api.nexgentaxes.com/',
	apiProdBase: 'https://production.api.nexgentaxes.com/',

	conversationApi: 'https://communications-service-staging.herokuapp.com/',
	googleApiKey: 'AIzaSyAIZJ12WI3UI3mbP2FC6u9JO9nkuzQmI7I',
	apiBaseKey: 'f488c628a5fdf8b17861bb8d4947a5b3eb00d2f8',

	stripe: {
		publishable:
			'pk_test_51KME7XBD9tkiJntN22IRp9quDR3BCh1PuAt8Aga3eKk5Cc3Yg1elsRuKxPI7gcuQZSqBhr4E7ysyYAH4zM61VpAu00KfCycdjP',
		secret: 'sk_test_51KME7XBD9tkiJntNTv5KWq6R1vXmeCbgRAIiwrtCN5Vqa9wmO8VDwbYMr3QKvAKqZ0xJHh7UwcDYRXMU7q4sAixk00Xvr6zsLq',
	},
	firebaseConfig: {
		apiKey: 'AIzaSyD0oVWENCpav5miZOAi7x4cJiBHupJoUbU',
		authDomain: 'nexgen-taxes-staging-5abfb.firebaseapp.com',
		projectId: 'nexgen-taxes-staging-5abfb',
		storageBucket: 'nexgen-taxes-staging-5abfb.appspot.com',
		messagingSenderId: '656143052757',
		appId: '1:656143052757:web:b066e029e588de85d3175c',
	},
	appIsFor: 'nexGenTaxes',
	supportId: 'LmSysmVSmAM8TqyHFWOsxfuynOD2',

	appLink: 'https://vendor.qa.nexgentaxes.com/',
};
