import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class FormattingService {
	selectedWrId = '';
	selectedWr = { selected: false, wrId: '' };
	constructor() {}

	getStatesList(request) {
		let states = [];

		// main states
		if (request?.questionnaire[5]?.answers) {
			const st = request.questionnaire[5].answers.filter(
				(a) => a.value === true
			);
			if (st && st.length > 0) states = st;
		}

		// comapany states
		if (request?.bizList && request?.bizList.length > 0) {
			request?.bizList.forEach((el: any) => {
				if (el && el.data && el.data.length > 0) {
					const st = el.data.find((dt: any) =>
						[
							'q5',
							'q16.1',
							'q23.39',
							'q25.39',
							'q27.3',
							'q47.2',
						].includes(dt.key)
					);

					if (st && st.answers) {
						const selectedStates = st.answers.filter(
							(s: any) => s.value
						);
						states = [...states, ...selectedStates];
					}
				}
			});
		}

		return states;
	}

	getAnswers(answers) {
		return answers.filter(
			(a: any) =>
				a.value === true || a.value.length > 0 || a.value === Number(0)
		);
	}

	getRushDelivery(request) {
		const rushDelivery = request.questionnaire
			.find((q) => q.key === 'q17')
			.answers?.find((a) => a.value === true)?.name;

		return rushDelivery ? rushDelivery : '';
	}

	getAddons(request) {
		if (request?.requestType.indexOf('Tax Return') >= 0) {
			const addons: string[] = [];
			const q18 = request?.questionnaire
				?.find((q) => q?.key === 'q18')
				?.answers?.find((a) => a?.value === true);
			const q19 = request?.questionnaire
				?.find((q) => q?.key === 'q19')
				?.answers?.find((a) => a?.value === true);
			const q21 = request?.questionnaire
				?.find((q) => q?.key === 'q21')
				?.answers?.find((a) => a?.value === true);

			if (q18?.key === 'yes') addons?.push('Audit Protection');
			if (q19?.key === 'yes') addons?.push('Return Advance Loans');
			else if (q19?.key === 'loan-more')
				addons?.push('Return Advance Loans');
			if (q21?.value === true) addons.push('Tax Shield');

			return addons;
		} else return [];
	}

	getPackageType(request: any) {
		const packageTypeString: string[] = [];
		if (
			request?.requestType.indexOf('Tax Return') >= 0 &&
			request.packageType &&
			request.packageType.length > 0
		) {
			if (
				request.packageType.includes('basic') ||
				request.packageType.includes('standard') ||
				request.packageType.includes('premium') ||
				request.packageType.includes('self-employed')
			) {
				packageTypeString.push('1040 | ');
				request.packageType.forEach((pT) => {
					if (pT !== 'business') {
						packageTypeString.push(
							pT.charAt(0).toUpperCase() + pT.slice(1)
						);
						if (request.packageType.includes('business')) {
							packageTypeString.push(' ');
						}
					}
				});
			} else if (request?.requestType.indexOf('Non-Profit') >= 0)
				packageTypeString.push(request.requestType);
			else if (request?.requestType.indexOf('Business') >= 0)
				packageTypeString.push('Business');

			// if (request.packageType.includes('business')) {
			// 	if (request?.bizList.length > 0) {
			// 		request.bizList.forEach((b, index) => {
			// 			const businessType = b.data
			// 				?.find((q) => q.key === 'q13')
			// 				?.answers?.find((a) => a.value === true)?.name;
			// 			if (businessType) {
			// 				packageTypeString.push(businessType.toUpperCase());
			// 				if (index + 1 < request.bizList.length)
			// 					packageTypeString.push(', ');
			// 			}
			// 		});
			// 	}
			// }
		} else if (request?.requestType) {
			packageTypeString.push(request.requestType);
		}
		const p = packageTypeString.join('');
		return p;
	}

	getRequestYear(request: any) {
		let requestYear = '';
		const y = request?.requestType;
		const quest =
			y === 'Non-Profit Tax Return'
				? 'q25.2'
				: y === 'Business Tax Return'
				? 'q23.2'
				: y === 'Tax Audit / Lien / Levy'
				? 'q50'
				: y === 'I Received a Notice'
				? 'q60'
				: y === 'Tax Consultation/Other Issues'
				? 'q70'
				: y === 'Company Formation' ||
				  y === 'Accounting or Bookkeeping' ||
				  y === 'Payroll'
				? ''
				: 'q2';

		if (quest) {
			const n =
				request && request.questionnaire
					? request.questionnaire
							.find((q) => q.key === quest)
							.answers?.find((a) => a.value)
					: '';
			if (n && n.value && ['q50', 'q60', 'q70'].includes(quest))
				requestYear = n.value;
			else if (n && n.name) requestYear = n.name;
		}
		return requestYear || '';
	}

	getPackageNames(request: any) {
		const string: any[] = [];
		let bizType = 0;
		if (request?.bizList) {
			request?.bizList.forEach((biz: any) => {
				biz.data.forEach((q: any) => {
					if (q.question === 'What type of business do you have?') {
						q.answers.forEach((a: any, idx: number) => {
							if (a.value) {
								string.push(
									bizType > 0 ? ', ' + a.name : a.name
								);
								++bizType;
							}
						});
					}
				});
			});
		}

		return string.length > 0 ? string.join('') : '';
	}

	getFullPackage(wr: any) {
		let wrTitle = '';
		const wrYear = wr && wr.id ? this.getRequestYear(wr) : '';
		const wrPack = wr && wr.id ? this.getPackageType(wr) : '';
		const pn = this.getPackageNames(wr);
		wrTitle = wrYear
			? wrYear + (wrYear && wrPack ? ' - ' : '') + wrPack
			: wrPack;

		wrTitle += pn ? ' | ' + pn : '';
		return wrTitle;
	}
}
