<ion-content>
	<ion-row>
		<ion-col id="header" class="ion-text-center">
			<h5>Upload Documents</h5>
		</ion-col>
	</ion-row>
	<ion-row>
		<ion-col>
			<ion-row>
				<ion-col>
					<ion-textarea
						class="upload-text border"
						[(ngModel)]="editorModel"
					>
					</ion-textarea>
				</ion-col>
			</ion-row>
			<div class="center">
				<button
					class="custom-btn"
					style="max-width: 200px; margin: 0.2rem auto"
					[disabled]="uploadingFile"
				>
					<div *ngIf="!uploadingFile">Add Documents</div>
					<ion-spinner *ngIf="uploadingFile"></ion-spinner>
					<input type="file" (change)="fileAdded($event)" />
				</button>
			</div>
			<div
				style="
					max-width: 600px;
					margin: 1rem auto;
					background-color: white;
				"
			>
				<ion-row
					id="docHeaders"
					class="border-bot"
					*ngIf="docs.length > 0"
				>
					<ion-col size="4" class="ion-text-center"
						><span class="header-text">Name</span></ion-col
					>
					<!-- <ion-col size="3" class="ion-text-center"
					><span class="header-text">Needs Signature</span></ion-col
				> -->
					<ion-col size="6" class="ion-text-center">
						<!-- <span class="header-text">Request Approval</span> -->
					</ion-col>
					<ion-col size="2"></ion-col>
				</ion-row>
				<ion-row
					class="doc-item border-bot"
					*ngFor="let doc of docs; let i = index"
				>
					<ion-col size="4" class="ion-text-center flex-center-xy"
						><span class="name">{{ doc.name }}</span></ion-col
					>
					<!-- <ion-col size="3" class="ion-text-center">
					<ion-toggle
						color="primary"
						(ionChange)="changeSignature($event, i)"
					></ion-toggle>
				</ion-col> -->
					<ion-col size="6" class="ion-text-center">
						<!-- <ion-toggle
						color="primary"
						(ionChange)="changeApproval($event, i)"
					></ion-toggle> -->
					</ion-col>
					<ion-col size="2" class="flex-center-xy"
						><ion-icon
							name="close-circle-outline"
							color="danger "
							id="deleteButton"
							style="font-size: 1.7rem"
						></ion-icon
					></ion-col>
				</ion-row>
			</div>

			<ion-row class="ion-justify-content-center" *ngIf="docs.length > 0">
				<ion-col size="4">
					<button
						class="custom-btn"
						(click)="sendDocuments()"
						[disabled]="sendingDocuments"
					>
						<span *ngIf="!sendingDocuments"> Send Documents </span>
						<ion-spinner *ngIf="sendingDocuments"></ion-spinner>
					</button>
				</ion-col>
			</ion-row>
		</ion-col>
	</ion-row>
</ion-content>
