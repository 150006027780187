import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { StepsStatusComponent } from './steps-status.component';

@NgModule({
	declarations: [StepsStatusComponent],
	exports: [StepsStatusComponent],
	imports: [
		CommonModule,
		IonicModule,
		RouterModule,
		NgCircleProgressModule.forRoot({}),
	],
})
export class StepsStatusModule {}
