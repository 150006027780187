import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
	Camera,
	CameraDirection,
	CameraResultType,
	CameraSource,
} from '@capacitor/camera';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class PhotoService {
	constructor(private http: HttpClient) {}

	uploadPhoto(photoName, sendBlob: boolean = false) {
		return new Promise<any>((resolve, reject) => {
			Camera.getPhoto({
				resultType: CameraResultType.Base64,
				width: 400,
				allowEditing: false,
				quality: 100,
				direction: CameraDirection.Rear,
			}).then(
				async (photo) => {
					const date = new Date().valueOf();
					const imageName = photoName + '_' + date + '.png';
					const blob = this.dataURItoBlob(photo.base64String);

					const imageFile: File = new File([blob], imageName, {
						type: 'image/png',
					});

					const formData = new FormData();
					formData.append('fi', imageFile);

					const data = await this.uploadPhotoToS3(formData);
					resolve(data.Location);
				},
				(canceled) => {
					reject('Canceled Photo Upload');
				}
			);
		});
	}

	uploadPhotoToS3(formData) {
		return new Promise<any>((resolved, rejected) => {
			const url = environment.apiBase + 'api/upload';

			this.http.post<any>(url, formData).subscribe(
				(res) => {
					resolved(res);
				},
				(err) => {
					rejected(err);
				}
			);
		});
	}

	dataURItoBlob(dataURI) {
		const byteString = window.atob(dataURI);
		const arrayBuffer = new ArrayBuffer(byteString.length);
		const int8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < byteString.length; i++) {
			int8Array[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([int8Array], { type: 'image/png' });
		return blob;
	}
}
