/* eslint-disable no-underscore-dangle */
import {
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
	constructor(
		private userProvider: UserProviderService,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): any {
		//
		let redirectionPath: string = '';

		if (this.userProvider.loggedInUser.id) {
			// For Testing - By Pass 2FA Check
			return true;
			if (
				this.userProvider.loggedInUser.twoFA &&
				this.userProvider.loggedInUser.verifiedAuth
			) {
				return true;
			} else if (
				this.userProvider.loggedInUser.twoFA &&
				!this.userProvider.loggedInUser.verifiedAuth
			) {
				this.router.navigate(['/onboarding/verify-auth'], {
					queryParams: { returnUrl: state.url },
				});
			} else if (!this.userProvider.loggedInUser.twoFA) {
				return true;
			}
		} else {
			redirectionPath = '/login';
			this.router.navigate([redirectionPath], {
				queryParams: { returnUrl: state.url },
			});
			return false;
		}
	}
}
