<!-------------------------left menu----------------------->

<div id="dash-menu" (click)="userProvider.resetChat()">
	<section>
		<div
			class="item"
			[routerLink]="['/dashboard']"
			routerLinkActive="activepage"
		>
			<section class="relative">
				<ion-icon
					name="home-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Home</span>
			</section>
		</div>

		<div
			class="item"
			[routerLink]="['/work-requests']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="create-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Work Request</span>
				<div
					*ngIf="this.leadWatcher.workRequests?.changed"
					class="notif"
				>
					<ion-icon
						style="margin: 0 auto; font-size: 1.15rem"
						name="notifications-outline"
					></ion-icon>
				</div>
			</section>
		</div>

		<div
			class="item relative"
			[routerLink]="['/messages']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="mail-outline"
					style="font-size: 25px"
				></ion-icon>
				<div class="class notif-messages msg-alert2">
					{{ userProvider.totalUnreadMsg }}
				</div>
				<span>Messages</span>
			</section>
		</div>

		<div
			class="item"
			[routerLink]="['/docs']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="folder-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Documents</span>
			</section>
		</div>

		<div
			[routerLink]="['/lead-purchase-options']"
			routerLinkActive="activepage"
			*ngIf="userProvider.loggedInUser.approvedToWork"
			class="item"
		>
			<section class="relative yellow">
				<ion-icon
					name="options-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Lead Purchase Options</span>
			</section>
		</div>

		<div
			routerLinkActive="activepage"
			*ngIf="userProvider.loggedInUser.approvedToWork"
			class="item"
			[routerLink]="['/message-template']"
		>
			<section class="relative">
				<ion-icon
					name="reader-outline"
					style="font-size: 25px"
				></ion-icon>

				<span>Message Template</span>
			</section>
		</div>
	</section>

	<section class="margin-bot-1">
		<div
			class="item"
			[routerLink]="['/support']"
			routerLinkActive="activepage"
		>
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="help-circle-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Support</span>
			</section>
		</div>

		<div class="item" (click)="openHelp()" routerLinkActive="activepage">
			<section
				*ngIf="userProvider.loggedInUser.approvedToWork"
				class="relative"
			>
				<ion-icon
					name="help-circle-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Help Center</span>
			</section>
		</div>

		<div class="item" (click)="logoutPrompt()" routerLinkActive="active">
			<section class="relative">
				<ion-icon
					name="log-out-outline"
					style="font-size: 25px"
				></ion-icon>
				<span>Logout</span>
			</section>
		</div>
	</section>
</div>
