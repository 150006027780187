<div class="flex-rowcenter" *ngIf="shape != 'line'">
	<div class="ion-text-start" id="progressCircle">
		<circle-progress
			(click)="showPercentAlert()"
			[titleFontSize]="'20'"
			unitsFontSIze="10"
			subtitleFontSize="10"
			titleColor="#000000"
			unitsColor="#000000"
			subtitleColor="#FFFFFF"
			units="%"
			[percent]="percentage"
			[radius]="66"
			[showSubtitle]="false"
			[backgroundPadding]="5"
			[outerStrokeWidth]="13"
			[space]="1"
			[innerStrokeWidth]="20"
			[outerStrokeColor]="_color"
			[innerStrokeColor]="'#dddddd'"
			[animation]="true"
			[animationDuration]="300"
			[startFromZero]="false"
			[maxPercent]="100"
			[backgroundColor]="'#f4f4fa'"
		></circle-progress>
	</div>
</div>
<section *ngIf="shape == 'line'">
	<ion-progress-bar value="percentage /10"></ion-progress-bar>
	<div>
		<span style="font-size: 12px !important; color: #777777"
			>{{ percentage.toFixed(0) }}% Completed</span
		>
	</div>
</section>
