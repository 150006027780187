import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	mainMenuActive: boolean = false;
	chatMenuActive: boolean = false;

	constructor() {}

toggleMenu(set: boolean = false, setTo: boolean = false) {
		this.mainMenuActive = set ? setTo : !this.mainMenuActive;
	}

	async toggleChatMenu() {
		this.chatMenuActive = !this.chatMenuActive;
	}
}
