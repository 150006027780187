import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NavigateAwayGuard } from './guards/navigate-away.guard';

const routes: Routes = [
	{
		path: 'login',
		loadChildren: () =>
			import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'register',
		loadChildren: () =>
			import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'dashboard',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/dashboard/dashboard.module').then(
				(m) => m.DashboardModule
			),
	},
	{
		path: 'docs',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/docs/docs.module').then((m) => m.DocsModule),
	},
	{
		path: 'lead-purchase-options',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/purchase-options/options.module').then(
				(m) => m.OptionsModule
			),
	},
	{
		path: 'onboarding',
		loadChildren: () =>
			import('./pages/onboarding/onboarding.module').then(
				(m) => m.OnboardingPageModule
			),
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.ProfilePageModule
			),
	},
	{
		path: 'my-profile',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/profile2/profile2.module').then(
				(m) => m.Profile2PageModule
			),
	},
	{
		path: 'messages',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/messages/messages.module').then(
				(m) => m.MessagesPageModule
			),
	},

	// {
	// 	path: 'invite',
	// 	canActivate: [AuthGuard],
	// 	loadChildren: () =>
	// 		import('./pages/invite/invite.module').then(
	// 			(m) => m.InvitePageModule
	// 		),
	// },

	{
		path: 'invite-users',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/invite-users/invite-users.module').then(
				(m) => m.InviteUsersPageModule
			),
	},
	{
		path: 'signup',
		loadChildren: () =>
			import('./pages/signup/signup.module').then((m) => m.SignupModule),
	},
	{
		path: 'signup/:id',
		loadChildren: () =>
			import('./pages/signup/signup.module').then((m) => m.SignupModule),
	},

	{
		path: 'work-requests',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/work-requests/work-requests.module').then(
				(m) => m.WorkRequestsPageModule
			),
	},
	{
		path: 'work-request',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/work-request/work-request.module').then(
				(m) => m.WorkRequestPageModule
			),
	},
	{
		path: 'work-request/:id',
		runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
		canActivate: [AuthGuard],
		canDeactivate: [NavigateAwayGuard],
		loadChildren: () =>
			import('./pages/work-request/work-request.module').then(
				(m) => m.WorkRequestPageModule
			),
	},

	{
		path: 'appointments',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/appointments/appointments.module').then(
				(m) => m.AppointmentsPageModule
			),
	},
	{
		path: 'invoices',
		canActivate: [AuthGuard],
		loadChildren: () =>
			import('./pages/invoices/invoices.module').then(
				(m) => m.InvoicesPageModule
			),
	},

	{
		path: 'support',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/support/support.module').then(
				(m) => m.SupportPageModule
			),
	},

	{
		path: 'password-reset',
		loadChildren: () =>
			import('./pages/password-reset/password-reset.module').then(
				(m) => m.PasswordResetPageModule
			),
	},
	{
		path: 'join-team',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/join-team/join-team.module').then(
				(m) => m.JoinTeamPageModule
			),
	},
	{
		path: 'help',
		loadChildren: () =>
			import('./pages/help/help.module').then((m) => m.HelpModule),
	},
	{
		path: 'credits',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/credits/credits.module').then(
				(m) => m.CreditsPageModule
			),
	},
	{
		path: 'verify-email/:id',
		// canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/verify-email/verify-email.module').then(
				(m) => m.VerifyEmailPageModule
			),
	},
	{
		path: 'message-template',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/msg-template/msg-template.module').then(
				(m) => m.MsgTemplateModule
			),
	},
	{
		path: 'invoice-template',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/invoice-template/invoice-template.module').then(
				(m) => m.InvoiceTemplatePageModule
			),
	},
	{
		path: 'all-invoices',
		canActivate: [AuthGuard],

		loadChildren: () =>
			import('./pages/all-invoices/all-invoices.module').then(
				(m) => m.AllInvoicesPageModule
			),
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: '/dashboard',
	},
	{
		path: '**',
		redirectTo: '/dashboard',
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
