import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICpaDocs } from 'lib/models/docs.model';
import { FirebaseApiService } from 'lib/services/firebase/firebase.api.service';

import { PhotoService } from 'lib-user/services/photo/photo.service';
import { UserProviderService } from 'lib-user/services/userProvider/user-provider.service';
import { UtilService } from 'lib-user/services/util/util.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-document-upload',
	templateUrl: './document-upload.component.html',
	styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent {
	@Input() componentProps;
	editorModel: string = '';
	docs: {
		url: string;
		name: string;
		requestedApproval: boolean;
		signatureRequested: boolean;
		createdAt: any;
		vId?: string;
		vFirstName?: string;
		vLastName?: string;
	}[] = [];
	uploadingFile: boolean = false;
	sendingDocuments: boolean = false;

	constructor(
		private photoService: PhotoService,
		private firebaseApi: FirebaseApiService,
		private util: UtilService,
		private modalCtrl: ModalController,
		private http: HttpClient,
		private userProvider: UserProviderService
	) {}

	async sendDocuments() {
		try {
			this.sendingDocuments = true;

			const findWr = await this.firebaseApi.findOneById(
				'workRequests',
				this.componentProps.workRequest.id
			);

			const newCpaDocs: ICpaDocs[] = [...findWr.cpaDocs, ...this.docs];

			await this.firebaseApi.updateOne(
				'workRequests',
				this.componentProps.workRequest.id,
				{
					cpaDocs: newCpaDocs,
				}
			);

			let fromEmail: string = '';
			switch (environment.appIsFor) {
				case 'nexGenTaxes':
					fromEmail = 'emails@nexgentaxes.com';
					break;
				case 'rushTimeTax':
					fromEmail = 'emails@rushtimetax.com';
					break;
				case 'forMyTax':
					fromEmail = 'emails@formytax.com';
					break;
			}

			if (this.editorModel.length > 0)
				await this.sendEmailToClient({
					email: this.componentProps.workRequest.userInfo.email,
					name:
						this.componentProps.workRequest.userInfo.firstName +
						' ' +
						this.componentProps.workRequest.userInfo.lastName,
					senderName:
						this.userProvider.loggedInUser.firstName +
						' ' +
						this.userProvider.loggedInUser.lastName,
					senderEmail: this.userProvider.loggedInUser.email,
					subject: `Email from your ${
						this.userProvider.loggedInUser.firstName +
						' ' +
						this.userProvider.loggedInUser.lastName
					} regarding your taxes.`,
					message: this.editorModel,
					fromEmail,
				});
		} catch (e) {
			this.util.createErrorAlert(null, 'Could not send documents');
		} finally {
			this.sendingDocuments = false;
			await this.modalCtrl.dismiss();
		}
	}

	sendEmailToClient(data) {
		return new Promise<any>((resolved, rejected) => {
			const url = environment.apiBase + 'emailFromVendor';
			this.http.post<any>(url, data).subscribe(resolved, rejected);
		});
	}

	changeApproval(event, index) {
		this.docs[index].requestedApproval = event.detail.checked;
	}

	changeSignature(event, index) {
		this.docs[index].signatureRequested = event.detail.checked;
	}

	async fileAdded($event) {
		try {
			this.uploadingFile = true;
			const file = $event.target.files[0];
			const formData = new FormData();
			formData.append('fi', file);
			const data = await this.photoService.uploadPhotoToS3(formData);
			const url = data.Location;
			this.docs.push({
				url,
				name: file.name,
				requestedApproval: false,
				signatureRequested: false,
				createdAt: new Date().toISOString(),
				vId: this.userProvider.loggedInUser?.id,
				vFirstName: this.userProvider.loggedInUser?.firstName
					? this.userProvider.loggedInUser?.firstName
					: '',
				vLastName: this.userProvider.loggedInUser?.lastName
					? this.userProvider.loggedInUser?.lastName
					: '',
			});
		} catch (e) {
			this.util.createErrorAlert(null, 'Error uploading file');
		} finally {
			this.uploadingFile = false;
		}
	}
}
