import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
	NavController,
	ToastController,
	AlertController,
	ModalOptions,
	ModalController,
	AnimationController,
} from '@ionic/angular';
import { ComponentRef } from '@ionic/core';

declare let google;

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	constructor(
		private toastController: ToastController,
		private alertController: AlertController,
		private navCtrl: NavController,
		private modalCtrl: ModalController,
		private animationCtrl: AnimationController,
		private router: Router
	) {}

	async slideInPage(component: ComponentRef, componentProps) {
		const modalOpts: ModalOptions = {
			component,
			componentProps: { componentProps },
			cssClass: 'slide-out-modal',
			animated: false,
		};
		const modal = await this.modalCtrl.create(modalOpts);
		await modal.present();
		modal.onDidDismiss().then((dismiss) => {
			this.router.navigate(['work-requests', componentProps.slug]);
		});
	}

	async presentCenterModal(component: ComponentRef, componentProps) {
		const modalOpts: ModalOptions = {
			component,
			componentProps: { componentProps },
			cssClass: 'center-modal-2',
			animated: true,
			showBackdrop: true,
		};
		const modal = await this.modalCtrl.create(modalOpts);
		await modal.present();
		modal.onDidDismiss().then((dismiss) => {});
	}

	async presentToastWithOptions(message: string) {
		const toast = await this.toastController.create({
			header: message,
			position: 'bottom',
			duration: 3000,
		});
		toast.present();
	}

	async navigateRoot(url, options?) {
		await this.navCtrl.navigateRoot(url, options);
	}
	navigateForward(url, options?) {
		this.navCtrl.navigateForward(url, options);
	}
	navigateBack(url, options?) {
		this.navCtrl.navigateBack(url, options);
	}

	navigate(url: string) {
		this.router.navigate([url]);
	}

	formatPhone($event) {
		let phone = $event.target.value.replace(/[^\d]/g, '');
		const len = phone.length;
		phone = len > 10 ? phone.substr(0, 10) : phone;
		phone =
			len > 3 ? phone.substr(0, 3) + '-' + phone.substr(3, len) : phone;
		phone =
			len > 6
				? phone.substr(0, 7) + '-' + phone.substr(7, len + 1)
				: phone;

		return phone;
	}

	reverseAddressSearch(address: string) {
		const geocoder = new google.maps.Geocoder();
		return new Promise<any>((resolve, reject) => {
			geocoder.geocode({ address }, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK) {
					if (results) {
						results = results[0];
						if (results && !results.err) {
							const rsltAdrComponent = results.address_components;
							let number;
							let street;
							let city;
							let state;
							let zipcode;

							for (let i = 0; i < rsltAdrComponent.length; i++) {
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'street_number'
									)
								) {
									number = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes('route')
								) {
									street = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'locality'
									)
								) {
									city = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'postal_code'
									)
								) {
									zipcode = rsltAdrComponent[i].short_name;
								}
								if (
									rsltAdrComponent[i].types &&
									rsltAdrComponent[i].types.includes(
										'administrative_area_level_1'
									)
								) {
									state = rsltAdrComponent[i].short_name;
								}
							}
							resolve({ number, street, city, state, zipcode });
						}
					}
				} else {
					resolve('Error reversing location results');
				}
			});
		});
	}

	async createToast(message, duration = 12000): Promise<HTMLIonToastElement> {
		const toast = await this.toastController.create({
			message,
			position: 'middle',
			duration,
			buttons: [
				{
					text: 'Ok',
					role: 'cancel',
					handler: () => {},
				},
			],
		});
		return toast;
	}

	async presentAlert(header: string, subHeader: string, message: string) {
		const alert = await this.alertController.create({
			header,
			subHeader,
			message,
			buttons: ['OK'],
		});

		await alert.present();
	}

	async presentToast(message: string) {
		const toast = await this.toastController.create({
			header: message,
			position: 'bottom',
			duration: 3000,
		});
		toast.present();
	}

	async createAlert(
		header,
		backdropDismiss,
		message,
		buttonOptions1,
		buttonOptions2?
	): Promise<HTMLIonAlertElement> {
		const alert = await this.alertController.create({
			header,
			backdropDismiss,
			message,
			buttons: !buttonOptions2
				? [buttonOptions1]
				: [buttonOptions1, buttonOptions2],
		});
		return alert;
	}

	async getGooglePlaceAutoCompleteList(searchText, geolocation, country) {
		const service = new google.maps.places.AutocompleteService();
		let pred;
		await new Promise((resolve, reject) => {
			service.getPlacePredictions(
				{
					input: searchText,
					componentRestrictions: { country: country || 'US' },
				},
				(predictions) => {
					pred = predictions;
					resolve(true);
				}
			);
		});
		return pred;
	}

	async createErrorAlert(title, message, func?) {
		const alert = await this.createAlert(title, false, message, {
			text: 'Ok',
			handler: async () => {
				if (func) func();
			},
		});
		await alert.present();
	}

	async showToast(message: string, duration?: number) {
		const toast = await this.createToast(message, duration);
		await toast.present();
	}
}
