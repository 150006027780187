<!--  -->
<style>
	.lds-facebook {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-facebook div {
		display: inline-block;
		position: absolute;
		left: 8px;
		width: 16px;
		background: #fff;
		animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
	}
	.lds-facebook div:nth-child(1) {
		left: 8px;
		animation-delay: -0.24s;
	}
	.lds-facebook div:nth-child(2) {
		left: 32px;
		animation-delay: -0.12s;
	}
	.lds-facebook div:nth-child(3) {
		left: 56px;
		animation-delay: 0;
	}
	@keyframes lds-facebook {
		0% {
			top: 8px;
			height: 64px;
		}
		50%,
		100% {
			top: 24px;
			height: 32px;
		}
	}

	.center-logo {
		width: 100%;
		height: 100vh;
		background-color: #6c63ff;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
</style>

<div class="center-logo" *ngIf="!appLoaded">
	<div
		class="logo-container"
		style="padding: 10px; border-radius: 16px; background-color: white"
	>
		<img
			width="100"
			_ngcontent-ivy-c149=""
			src="assets/icon/nexgenlogo.png"
		/>
	</div>
	<div class="lds-facebook app">
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>
<ion-app>
	<ion-router-outlet [animated]="false"></ion-router-outlet>
</ion-app>
