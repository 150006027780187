import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import 'firebase/firestore';
import { FirestoreService } from './firestore.service';

@Injectable({
	providedIn: 'root',
})
export class FirebaseApiService {
	constructor(private firestore: FirestoreService) {}

	/**
	 * Find all documents in a set collection. If query parameter provided
	 * then find all documents in a set collection which also match the query.
	 *
	 * @param collection
	 * @param query optional
	 * @returns Array of all documents in a collection, that match the query if a query is provided
	 */
	async findAll(collection: string, query?) {
		return new Promise<any>((resolve, reject) => {
			this.firestore
				.find(collection, query ? query : null)
				.subscribe(resolve, reject);
		});
	}

	async delete(collection, id) {
		return new Promise<any>(async (resolve, reject) => {
			try {
				await this.firestore.delete(collection, id);
				resolve(true);
			} catch (e) {
				reject(e);
			}
		});
	}

	/**
	 * Find a document from a set collection by the documents _id.
	 *
	 * @param collection
	 * @param id
	 * @returns Data of the found document
	 */
	async findOneById(collection: string, id: string) {
		return new Promise<any>((resolve, reject) => {
			this.addIdToObject(
				id,
				this.firestore.getOne(collection, id)
			).subscribe(resolve, reject);
		});
	}

	/**
	 * @param collection
	 * @param data
	 * @returns id of inserted document
	 */
	async insertOne(collection, data) {
		return new Promise<any>((resolve, reject) => {
			this.snapshotToDataConverter(
				this.firestore.create(collection, data)
			).subscribe((res: any) => resolve(res.id), reject);
		});
	}

	/**
	 * @param collection
	 * @param id
	 * @param data
	 * @returns
	 */
	updateOne(collection, id, data) {
		return new Promise(async (resolve, reject) => {
			from(this.firestore.update(collection, id, data)).subscribe(
				resolve,
				reject
			);
		});
	}

	addIdToObject(id, obj: Observable<any>) {
		return new Observable((observer) => {
			if (id) {
				obj.subscribe(
					(ref) => {
						const newObj = ref;
						if (newObj) {
							newObj.id = id;
						}
						observer.next(newObj);
					},
					(err) => {
						observer.error(err);
					}
				);
			} else {
				observer.error({ message: 'No ID' });
			}
		});
	}

	snapshotToDataConverter(query: Promise<any>) {
		return new Observable((observer) => {
			query
				.then((ref) => {
					const obj = ref.data();
					obj.id = ref.id;
					observer.next(obj);
				})
				.catch((err) => {
					observer.error(err);
				});
		});
	}

	getUserByEmail(email) {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('users', (ref) =>
					ref.where('email', '==', email)
				)
			).subscribe(resolve, reject);
		});
	}

	findTranscriptByRoomSid(roomSid) {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('transcripts', (ref) =>
					ref.where('roomSid', '==', roomSid)
				)
			).subscribe(resolve, reject);
		});
	}

	findConversationsByParticipant(userId): Promise<any[]> {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('conversations', (ref) =>
					ref.where('participants', 'array-contains', userId)
				)
			).subscribe(resolve, reject);
		});
	}

	findConversationsByParticipantAndWrId(userId, wRid): Promise<any[]> {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('conversations', (ref) =>
					ref
						.where('participants', 'array-contains', userId)
						.where('workRequestId', '==', wRid)
				)
			).subscribe(resolve, reject);
		});
	}

	findConversationsByConvoSid(convoSid): Promise<any[]> {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('conversations', (ref) =>
					ref.where('conversationSid', '==', convoSid)
				)
			).subscribe(resolve, reject);
		});
	}

	findConversationQueueByConvoSid(convoSid): Promise<any[]> {
		return new Promise(async (resolve, reject) => {
			from(
				this.firestore.find('conversationsQueue', (ref) =>
					ref.where('conversationSid', '==', convoSid)
				)
			).subscribe(resolve, reject);
		});
	}
}
